::-moz-selection {
    background: #4db5ff;
    color: #fafafa;
    text-shadow: none;
}

::selection {
    background: #4db5ff;
    color: #fafafa;
    text-shadow: none;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
html {
    overflow: scroll;
    overflow-x: hidden;
    font-size: 16px;
    scroll-behavior: smooth;
}
body {
    overflow-y: auto;
    transition: all 0.5s ease;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1,7;
}

p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    overflow-x: hidden;
    --color-bg: #101820FF;
    --color-bg-variant: #101820FF;
    --color-primary: #4db5ff;
    --color-primary-variant: #4db5ff;
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

::-webkit-scrollbar {
    display: none;
}

/* ======================   GENERAL STYLES =============== */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
    font-size: 1.5rem;
}

h1 {
    font-size: 2.5rem;
}

section{
    margin-top: 8rem;
}

section > h2, section > h5{
    text-align: center;
    color: var(--color-light);
}

section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.9rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/*================== MEDIA QUERIES (MEDIUM DEVICES) =============*/
@media screen and (max-width:1024px) {
    .container{
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/*================== MEDIA QUERIES (SMALL DEVICES) =============*/
@media screen and (max-width:600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}


