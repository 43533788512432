/* Default styles for larger screens */
.card-group {
  max-width: 2000px;
  display: flex;
  flex-wrap: nowrap; /* Prevent cards from wrapping to the next line */
  justify-content: center; /* Horizontally center the cards within the container */
  column-gap: 16px;
  padding: 50px;
  overflow-x: scroll;
}

.card {
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  height: 300px;
  background-image: linear-gradient(85deg, #101820FF, #000000);
  padding: 32px;
  border-radius: 8px;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  border-color: var(--color-primary-variant);
  transition: transform 250ms;
}

.card:not(:first-child) {
  margin-left: -130px;
}

.card:hover {
  transform: translate(-8px, -16px) rotate(3deg);
}

.card:hover ~ .card {
  transform: translateX(130px);
}

.card-date {
  display: block;
  color: #777777;
  margin-bottom: 3px;
  font-size: 14px;
}

.card-date strong {
  color: #d4d4d4;
}

.card-title {
  font-size: 26px;
  color: #fff;
  text-decoration: none;
}

.card-footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #fff;
}

.card-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.card-image img {
  display: block;
  width: 100%;
}

/* Media query for medium-sized phone screens */
@media (max-width: 768px) {
  .card-group {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .card {
    flex: 0 0 auto;
    margin-left: 0;
    width: 100%;
  }

  .card:not(:last-child) {
    margin-bottom: 20px; /* Add margin between cards */
    margin-left: 0;
  }

  .card:last-child {
    margin-bottom: 0; /* Remove margin for the last card */
    margin-left: 0;
  }

  .card:hover {
    transform: none;
  }

  .card:hover ~ .card {
    transform: none;
  }
}
