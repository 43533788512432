.contact__container {
    width: 100%;
    display: grid;
    grid-template-columns: 500% 19%;
    gap: 0%;
    justify-content: center;
}

.contact__option h5 {
    color: var(--color-light);
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

/*================== MEDIA QUERIES (MEDIUM DEVICES) =============*/
@media screen and (max-width: 1024px) {
    .contact__container {
        grid-template-columns: 300px;
    }
}

/*================== MEDIA QUERIES (SMALL DEVICES) =============*/
@media screen and (max-width: 600px) {
    .contact__container {
        width: 100%; /* Make the container full width */
        padding: 0 20px; /* Add optional padding to the sides */
        grid-template-columns: 300px;
    }
}

